import * as React from "react";
import SocialLink from "../components/SocialLink";
import BgLogoSVG from "../components/svgs/BgLogoSVG";
import '../styles/global.css';
import '../styles/home.css';
import { LINKS } from '../components/LINKS.js';

export default function IndexPage() {
  return (
    <main id="home">
      <a className="bgLogo" href="./">
        <BgLogoSVG />
      </a>
      <div className="message-box">
        <p className="introduction">Hi, my name is</p>
        <h1 className="title">Brodie Griggs</h1>
      </div>
      
      <div className='bio'>
        <p>
          I am a creative and driven university graduate, passionate about software development.
          I love solving tech problems and creating engaging and intuitive applications.
          <br/><br/>
          This website is currently under development, but will soon be up and running as a collage
          of the interesting projects I am working on or have in the past.
          <br/><br/>
          If you would like to learn more about me, feel free to view my CV
          or LinkedIn. You can find my public coding projects on my GitHub and CodePen,
          and my creative work on my Instagram.
        </p>
      </div>
      <a className="cv-btn" href="./about"><p>View my CV</p></a>
      <nav className="social-links">
        {LINKS.map((link, index) => {
          return <SocialLink
          socialName={link.text}
          url={link.url}
          iconSVG={link.icon}
          key={index}/>
        })}
      </nav>
    </main>
  )
};

export const Head = () => <title>Brodie Griggs</title>;
