import React from 'react'

export default function SocialLink({url, socialName, iconSVG}) {
  return (
    <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className='social-link' >
        
        {iconSVG}
        <p className='social-link-text'>{socialName}</p>
    </a>
  )
}
